const SliderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M0 13C0 7.99358 0 5.49037 1.12687 3.65148C1.75741 2.62253 2.62253 1.75741 3.65148 1.12687C5.49037 0 7.99358 0 13 0C18.0064 0 20.5096 0 22.3485 1.12687C23.3775 1.75741 24.2426 2.62253 24.8731 3.65148C26 5.49037 26 7.99358 26 13C26 18.0064 26 20.5096 24.8731 22.3485C24.2426 23.3775 23.3775 24.2426 22.3485 24.8731C20.5096 26 18.0064 26 13 26C7.99358 26 5.49037 26 3.65148 24.8731C2.62253 24.2426 1.75741 23.3775 1.12687 22.3485C0 20.5096 0 18.0064 0 13Z"
        fill="#121212"
      />
      <path
        d="M16.972 8.66667C15.5256 8.66667 14.2994 9.21189 13.565 10.0926C12.9296 9.12801 11.8184 8.75953 10.4933 8.75953H5.33331V17.3273H7.44712V10.3473C7.44712 10.3473 8.57108 10.3473 9.9856 10.3473C11.2756 10.3473 12.0962 11.1441 12.0962 12.678V17.3333H14.1557V12.9805C14.1557 11.2819 15.1232 10.4102 16.5282 10.4102C17.8214 10.4102 18.5781 11.1441 18.5781 12.675V17.3303H20.6377V12.4233C20.6377 9.84399 19.1688 8.66966 16.9752 8.66966L16.972 8.66667Z"
        fill="white"
      />
    </svg>
  )
}

export default SliderIcon


const LogoMini = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M0 16C0 9.83825 0 6.75737 1.38692 4.49413C2.16297 3.22773 3.22773 2.16297 4.49413 1.38692C6.75737 0 9.83825 0 16 0C22.1618 0 25.2426 0 27.5059 1.38692C28.7723 2.16297 29.837 3.22773 30.6131 4.49413C32 6.75737 32 9.83825 32 16C32 22.1618 32 25.2426 30.6131 27.5059C29.837 28.7723 28.7723 29.837 27.5059 30.6131C25.2426 32 22.1618 32 16 32C9.83825 32 6.75737 32 4.49413 30.6131C3.22773 29.837 2.16297 28.7723 1.38692 27.5059C0 25.2426 0 22.1618 0 16Z"
        fill="#0368FE"
      />
      <path
        d="M20.8886 10.6667C19.1084 10.6667 17.5993 11.3377 16.6954 12.4217C15.9134 11.2345 14.5457 10.781 12.9148 10.781H6.56407V21.326H9.16568V12.7351C9.16568 12.7351 10.549 12.7351 12.29 12.7351C13.8777 12.7351 14.8877 13.7159 14.8877 15.6036V21.3333H17.4224V15.976C17.4224 13.8855 18.6132 12.8125 20.3424 12.8125C21.934 12.8125 22.8654 13.7159 22.8654 15.6V21.3296H25.4002V15.2902C25.4002 12.1157 23.5924 10.6704 20.8926 10.6704L20.8886 10.6667Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoMini

export const HEADER_LINKS = [
  {
    title: 'Comprar',
    href: 'busqueda/venta'
  },
  {
    title: 'Rentar',
    href: '/busqueda/renta'
  },
  {
    title: 'Estimación de valor',
    href: '/appraisals'
  }
]

const LogoMonopolio = ({ fill = '#121212' }: { fill: string }) => {
  return (
    <svg
      width="135"
      height="24"
      viewBox="0 0 135 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.513 2C114.513 0.888338 115.403 0 116.603 0C117.823 0 118.713 0.818859 118.699 1.93052C118.699 3.09677 117.803 4 116.603 4C115.403 4 114.513 3.11166 114.513 2Z"
        fill={fill}
      />
      <path d="M109.028 1H112.168V19H109.028V1Z" fill={fill} />
      <path
        d="M12.3835 8.13896C13.4883 6.81784 15.3328 6 17.5088 6L17.5136 6.00449C20.8136 6.00449 23.0233 7.76599 23.0233 11.635V18.9955H19.925V12.0124C19.925 9.71621 18.7866 8.61528 16.8411 8.61528C14.7276 8.61528 13.2721 9.92292 13.2721 12.4708V19H10.1738V12.0169C10.1738 9.71621 8.93934 8.52091 6.99872 8.52091H3.17993V18.991H0V6.1393H7.76248C9.75594 6.1393 11.4276 6.69202 12.3835 8.13896Z"
        fill={fill}
      />
      <path
        d="M42.907 6H50.795C54.1434 6 56.5456 7.64091 56.5113 11.5545V19H53.2413V11.9364C53.2413 9.61364 52.1089 8.5 50.0253 8.5H46.221V19H42.907V6Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9591 6H75.3488V24H78.6236V17.4359C79.7427 18.6323 81.1928 19.1873 82.9591 19.1873C87.0124 19.1873 90 16.558 90 12.514C90 8.47005 87.0124 6 82.9591 6ZM82.6039 16.5762C80.2634 16.5762 78.6138 14.9977 78.6138 12.5004V8.42456H82.6039C84.9444 8.42456 86.7107 10.003 86.7107 12.5004C86.7107 14.9977 84.9444 16.5762 82.6039 16.5762Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.093 13C92.093 8.90034 95.0475 6 99.2854 6C103.577 6 106.744 8.90034 106.744 13C106.744 17.0997 103.523 20 99.2854 20C95.0475 20 92.093 17.0997 92.093 13ZM95.1976 12.9761C95.1976 15.5993 96.9558 17.2573 99.2854 17.2573C101.634 17.2573 103.586 15.5993 103.586 12.9761C103.586 10.3529 101.615 8.69488 99.2854 8.69488C96.9558 8.69488 95.1976 10.3529 95.1976 12.9761Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.797 6C61.5591 6 58.6046 8.90034 58.6046 13C58.6046 17.0997 61.5591 20 65.797 20C70.035 20 73.2558 17.0997 73.2558 13C73.2558 8.90034 70.0883 6 65.797 6ZM65.797 17.2573C63.4674 17.2573 61.7092 15.5993 61.7092 12.9761C61.7092 10.3529 63.4674 8.69488 65.797 8.69488C68.1267 8.69488 70.0979 10.3529 70.0979 12.9761C70.0979 15.5993 68.1461 17.2573 65.797 17.2573Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1163 13C25.1163 8.90034 28.0707 6 32.3087 6C36.5999 6 39.7674 8.90034 39.7674 13C39.7674 17.0997 36.5466 20 32.3087 20C28.0707 20 25.1163 17.0997 25.1163 13ZM28.2209 12.9761C28.2209 15.5993 29.979 17.2573 32.3087 17.2573C34.6577 17.2573 36.6096 15.5993 36.6096 12.9761C36.6096 10.3529 34.6383 8.69488 32.3087 8.69488C29.979 8.69488 28.2209 10.3529 28.2209 12.9761Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.541 6C123.303 6 120.349 8.90034 120.349 13C120.349 17.0997 123.303 20 127.541 20C131.779 20 135 17.0997 135 13C135 8.90034 131.832 6 127.541 6ZM127.541 17.2573C125.212 17.2573 123.453 15.5993 123.453 12.9761C123.453 10.3529 125.212 8.69488 127.541 8.69488C129.871 8.69488 131.842 10.3529 131.842 12.9761C131.842 15.5993 129.89 17.2573 127.541 17.2573Z"
        fill={fill}
      />
      <path d="M118.256 6H115.116V19H118.256V6Z" fill={fill} />
    </svg>
  )
}

export default LogoMonopolio

interface Props {
  children: React.ReactNode
  onClick?: () => void
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string
}

const Button = ({
  children,
  variant = 'primary',
  onClick,
  className
}: Props) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={[
        className,
        variant === 'tertiary' &&
          ' bg-button-blue-01 text-button-primary !text-body-bold-l',
        variant === 'secondary' &&
          ' border-button-primary text-button-primary border-[2px] ',
        variant === 'primary' &&
          'bg-button-primary text-surface-white border-none ',
        'transition-all duration-150 active:scale-95 text-body-m font-bold w-full px-[14px] py-2 rounded-3xl  outline-[5px] min-h-[44px] '
      ].join(' ')}
    >
      {children}
    </button>
  )
}

export default Button

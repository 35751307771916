import { useEffect, useRef, useState } from 'react'
import { HEADER_LINKS } from '../../utils/headerLinks'
import Button from './Button'

import LogoMonopolio from './LogoMonopolio'
import LogoMini from './LogoMini'

import SideBar from './SideBar'

const Navbar = () => {
  const navRef = useRef(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const redirectLogin = () => {}

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      if (offset > 35) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <nav
      ref={navRef}
      className={[
        isScrolled &&
          '![box-shadow:0px_5px_30px_-5px_rgba(24,26,61,0.20)] !bg-surface-dark-blue-01 ',
        'py-3 flex justify-between items-center w-full h-full transition-all duration-200 px-4 md:px-6  bg-transparent '
      ].join(' ')}
    >
      <div className="flex items-center justify-start gap-6 ">
        <a href="/">
          <div className="hidden md:block">
            <LogoMonopolio fill={isScrolled ? 'white' : '#121212'} />
          </div>
          <div className="flex md:hidden">
            <LogoMini />
          </div>
        </a>
        <div className="h-full w-auto  items-center hidden md:flex gap-6">
          {HEADER_LINKS.map((link) => (
            <a
              key={link.href}
              href={link.href}
              className={[
                isScrolled ? 'text-surface-white' : 'text-primary',
                'text-body-m  flex h-full items-center'
              ].join(' ')}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
      <div className="hidden">
        <div className="hidden md:block">
          <Button onClick={redirectLogin}> Iniciar sesión</Button>
        </div>
      </div>

      <div className="flex items-center md:hidden">
        <SideBar isScrolled={isScrolled} />
      </div>
    </nav>
  )
}

export default Navbar

import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import {
  MdOutlineChevronRight,
  MdOutlineClose,
  MdOutlineFavoriteBorder,
  MdOutlineHome,
  MdOutlineMenu
} from 'react-icons/md'
import Divider from './Divider'
import Button from './Button'
import LogoMini from './LogoMini'

import SliderIcon from './SliderIcon'

interface Props {
  isScrolled: boolean
}
export default function SideBar({ isScrolled }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const redirectLogin = () => {}

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  const options = [
    {
      icon: <SliderIcon />,
      title: 'Inicio',
      href: '/'
    },
    {
      icon: <MdOutlineHome className="!w-6 !h-6" />,
      title: 'Comprar',
      href: 'busqueda/venta'
    },
    {
      icon: <MdOutlineHome className="!w-6 !h-6" />,
      title: 'Rentar',
      href: '/busqueda/renta'
    },
    {
      icon: <MdOutlineHome className="!w-6 !h-6" />,
      title: 'Estimación de valor',
      href: '/appraisals'
    },

    {
      icon: <MdOutlineFavoriteBorder className="!w-6 !h-6" />,
      title: 'Propiedades guardadas',
      href: '/busqueda/favorites/list'
    },

    {
      icon: <MdOutlineFavoriteBorder className="!w-6 !h-6" />,
      title: 'Estimaciones guardadas',
      href: '/appraisals/favorites'
    }
  ]

  return (
    <>
      <button
        onClick={handleMenu}
        type="button"
        className="flex items-center justify-center h-full md:hidden "
      >
        <MdOutlineMenu
          className={[
            '!w-6 !h-6 ',
            isScrolled ? '!fill-button-primary !text-white' : 'fill-black'
          ].join(' ')}
        />
      </button>

      {createPortal(
        <>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: 1
                }}
                exit={{
                  opacity: 0
                }}
                className="w-screen h-screen fixed z-side-menu  bg-overlay-dark [backdrop-filter:blur(5px)]"
              >
                <motion.div
                  initial={{
                    translateX: '200px',
                    opacity: 0
                  }}
                  animate={{
                    translateX: 0,
                    opacity: 1
                  }}
                  exit={{
                    translateX: '200px',
                    opacity: 0
                  }}
                  transition={{ type: 'just', delay: 0.2 }}
                  className="fixed z-side-menu w-full top-0 right-0  h-screen  bg-surface-white flex flex-col px-6"
                >
                  <div className="flex justify-between items-center py-3">
                    <a href="/">
                      <LogoMini />
                    </a>
                    <button
                      onClick={handleMenu}
                      type="button"
                      className="w-8 h-8 flex items-center justify-center rounded-lg bg-button-gray-01"
                    >
                      <MdOutlineClose className="!w-6 !h-6" />
                    </button>
                  </div>
                  <Divider />

                  <Button
                    onClick={redirectLogin}
                    variant="primary"
                    className="mb-3 hidden"
                  >
                    Iniciar sesión
                  </Button>

                  <div id="links" className="flex flex-col w-full mb-3">
                    <p className="text-2xl font-bold text-text-primary pb-3">
                      Menú
                    </p>

                    {options.map((opt) => (
                      <a
                        key={opt.title}
                        href={opt.href}
                        className="flex w-full justify-between items-center"
                      >
                        <div className="flex items-center justify-start gap-x-4 h-14">
                          <div>{opt.icon}</div>
                          <p>{opt.title}</p>
                        </div>
                        <MdOutlineChevronRight className="!w-6 !h-6" />
                      </a>
                    ))}
                  </div>

                  <div className="w-full" />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </>,
        document.body
      )}
    </>
  )
}
